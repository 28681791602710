import { domain } from '@/siteInfo'
import { fetch } from '@/utils/fetchs.js'
// 对账角色列表
export const roleList = (params = {}) => fetch(domain + '/admin/v1/staff/roleList', params)

// 添加员工
export const userAdd = (params = {}) => fetch(domain + '/admin/v1/staff/userAdd', params)

// 员工详情
export const userInfoFn = (params = {}) => fetch(domain + '/admin/v1/staff/userInfo', params)

// 员工编辑
export const userEdit = (params = {}) => fetch(domain + '/admin/v1/staff/userEdit', params)
